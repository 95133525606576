.aboutMe {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 70px;
}

.aboutMe__paragraph {
  color: #555;
  width: 80%;
}

.aboutMe__paragraph > p {
  margin: 30px;
}

.aboutMe__skills {
  display: flex;
  justify-content: space-between;
}

.aboutMe__skill {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.aboutMe__skill > h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.25;
}

.aboutMe__skill > p {
  color: #555;
  margin: 20px;
  width: 90%;
}

.aboutMe__icons {
  display: flex;
  width: 100%;
  justify-content: center;
}

.aboutMe__icons > i {
  font-size: 75px;
  margin: 10px;
}

@media (max-width: 500px) {
  .aboutMe {
    width: 100%;
    height: 100%;
    margin-top: 70px;
  }

  .aboutMe__paragraph {
    width: 100%;
  }

  .aboutMe__skills {
    margin: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .aboutMe__skill {
    margin-bottom: 50px;
  }
}
