.projects {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.projects > h1 {
  text-align: center;
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

.projects__projectOne {
  display: flex;
  flex-direction: column;
  /* margin-top: -300px; */
  margin-bottom: 20px;
  min-height: 500px;
}

/* .projects__projectTwo {
    display: flex;
    flex-direction: flex;
    margin-top: 50px;
    margin-bottom: 50px;
} */

.projects__info {
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .projects__info {
    margin-bottom: -160px;
  }
}

.projects__embed {
  display: flex;
  flex: 1;
  height: 70vh;
  justify-content: center;
}

.projects__clones {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.projects__clones > embed {
  margin: 20px;
  flex: 0.5;
  height: 400px;
  border: 1px solid lightblue;
  transition: all 0.2s ease-in-out;
}

.projects__clones > embed:hover {
  transform: scale(1.05);
}

.projects__embed > embed {
  width: 80%;
  transition: all 0.2s ease-in-out;
}

.projects__embed > embed:hover {
  transform: scale(1.05);
}

.projects__featured {
  display: flex;
  margin-top: 40px;
  height: 50%;
  margin-bottom: 20px;
}

.projects__featuredThree {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px;
  height: 50%;
  margin-bottom: 40px;
}

.projects__details {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  margin: 0 auto;
}

.projects__detailsThree {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.projects__details > h3,
h5,
p {
  margin: 10px;
}

.projects__detailsThree > h3,
h5,
p {
  margin: 10px;
}

.projects__details > a {
  text-decoration: none;
}

.projects__details > a > .MuiButton-root {
  border-radius: 999rem;
}

.projects__detailsThree > a {
  text-decoration: none;
}

.projects__detailsThree > a > .MuiButton-root {
  border-radius: 999rem;
}

.projects__infoTwo {
    display: flex;
  flex: 0.5;
  justify-content: center;
}

.projects__vidTwo {
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.projects__infoThree {
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.projects__vidThree {
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.projects__vidTwo > video {
  height: 550px;
}

.projects__vidThree > video {
  height: 550px;
}

@media (max-width: 500px) {
  .projects__project {
    flex: 1;
    flex-direction: column;
  }

  .projects__info {
    flex: 1;
  }

  .projects__embed {
    flex: 1;
    width: 100%;
  }

  .projects__projectOne {
    flex: 1;
    flex-direction: column;
  }

  .projects__projectTwo {
    flex-direction: column;
  }

  .projects__clones {
    display: flex;
    width: 100%;
    flex-flow: wrap;
  }

  .projects__clones > embed {
    flex: 1;
    width: 100%;
  }

  .projects__featured {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .projects__featuredTwo {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .projects__featuredThree {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .projects__infoTwo {
    width: 100%;
  }

  .projects__infoThree {
    width: 100%;
  }

  .projects__details {
    width: 80%;
  }

  .projects__detailsThree {
    width: 80%;
  }

  .projects__vidTwo {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  
  .projects__vidThree {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
}
