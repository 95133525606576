.welcome {
  background: #1a222c;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  width: 100%;
}

.welcome__name {
  font-size: 70px;
  font-weight: bold;
  width: 100%;
}

.welcome__title {
  font-size: 30px;
}

.welcome__links > a > i > .MuiSvgIcon-root {
  font-size: 40px;
  border: 1px solid white;
  border-radius: 999rem;
  margin: 10px;
  padding: 10px;
}

.welcome__links > a {
  all: unset;
  color: white;
}

.welcome__links > a:hover {
  color: #555;
}

.welcome__links > a > i > .MuiSvgIcon-root:hover {
  background-color: white;
  cursor: pointer;
}

.welcome__sections > .MuiButton-root {
  color: white;
  text-transform: none;
  font-size: 20px;
}

.welcome__sections > a {
  text-decoration: none;
}

.welcome__sections > a > .MuiButton-root {
  color: white;
  text-transform: none;
  font-size: 20px;
}